import {
  Box,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import React, { useEffect } from "react";
import { useState } from "react";
import Loading from "../../../Components/Loading";
import ShopCard from "./ShopCard";

const Shop = () => {
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(8);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = 'Camera Bazar || Shop';
  })

  const pages = Math.ceil(count / size);

  const perPageHandleChange = (event) => {
    setSize(event.target.value);
  };

  const handleChange = (e, p) => {
    console.log(p);
    setPage(p - 1);
  };

  // const { data: allProducts, isLoading } = useQuery({
  //   queryKey: ["allProducts"],
  //   queryFn: async () => {
  //     const res = await fetch(
  //       `https://camerabazar-server.vercel.app/allproducts?page=${page}&size=${size}`
  //     );
  //     // "https://camerabazar-server.vercel.app/allproducts"
  //     // `http://localhost:4000/allproducts?page=${page}&size=${size}`
  //     const data = res.json();
  //     return data;
  //   },
  // });

  // useEffect(() => {
  //   const url = `https://camerabazar-server.vercel.app/allproducts?page=${page}&size=${size}`;
  //   // const url = `http://localhost:4000/allproducts?page=${page}&size=${size}`;
  //   fetch(url)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setCount(data.count);
  //       setProducts(data.allProducts);
  //     });
  // }, [page, size]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://camerabazar-server.vercel.app/shop?page=${page}&size=${size}`;
        // const url = `http://localhost:4000/allproducts?page=${page}&size=${size}`;
        fetch(url)
          .then((res) => res.json())
          .then((data) => {
            setCount(data.count);
            setProducts(data.allProducts);
            setIsLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [page, size]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Stack sx={{ margin: 0, height: '100%' }}>
      <Box
        sx={{
          display: "flex",
          placeItems: "center",
          justifyContent: "space-around",
          paddingX: { sx: 6, lg: 10 },
        }}
      >
        <Typography>Total Product Found: {count} </Typography>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 60 }} size="small">
            {/* <InputLabel id="demo-select-small">Per Page</InputLabel> */}
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={size}
              // label="Per Page"
              onChange={perPageHandleChange}
            >
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={20}>16</MenuItem>
              <MenuItem value={32}>32</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          marginY: "20px",
          gap: 3,
        }}
      >
        {products.map((product) => (
          <ShopCard key={product._id} product={product}></ShopCard>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          margin: "30px 0px",
          gap: 2,
        }}
      >
        {/* <ShopPagination /> */}

        <Stack spacing={2}>
          <Pagination count={pages} shape="rounded" onChange={handleChange} />
          {/* <Pagination count={10} variant="outlined" shape="rounded" /> */}
        </Stack>
      </Box>
    </Stack>
  );
};

export default Shop;
