import { Controls, Player } from '@lottiefiles/react-lottie-player';
import React from 'react';

const Loading = () => {
    return (
        // <div className='grid mx-auto'>
        //     <p className='mx-auto text-2xl text-blue-600 font-bold'>Please wait ...</p>
        // </div>
        <Player
            autoplay
            loop
            src="https://assets5.lottiefiles.com/packages/lf20_r620jxud.json"
            style={{ height: '300px', width: '300px' }}
        >
            <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
        </Player>
    );
};

export default Loading;