import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import "./AddBrand.css";
import { PhotoIcon } from "@heroicons/react/24/solid";
import JoditEditor from "jodit-react";
import toast, { Toaster } from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../../Components/Loading";
import BrandTable from "../Brand/BrandTable";
import { useLoaderData } from "react-router-dom";


const EditBrand = ({ placeholder }) => {
    const brand = useLoaderData();
    console.log(brand.name)
    const { _id, name, image, description } = brand;
    // const navigate = useNavigate();
    // const location = useLocation();
    const [imagePreview, setImagePreview] = useState();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const editor = useRef(null);
    const [setDetails] = useState("");
    const imageKey = process.env.REACT_APP_imageBB_key;

    // const from =
    //   location.state?.from?.pathname || `/dashboard/EditBrand/${_id}`;

    const des = {
        readonly: false, // all options from https://xdsoft.net/jodit/docs/,
        placeholder: placeholder || "Add description",
    };

    function handleChange(e) {
        console.log(e.target.files);
        setImagePreview(URL.createObjectURL(e.target.files[0]));
    }

    const onSubmit = (data) => {
        console.log(data);
        const image = data.brandImage[0];
        const formData = new FormData();
        formData.append("image", image);
        const url = `https://api.imgbb.com/1/upload?key=${imageKey}`;
        fetch(url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((imgData) => {
                console.log(imgData);
                const updateBrand = {
                    name: data.brandName,
                    image: imgData.data.url,
                    description: description,
                    submissionTime: new Date().getTime(),
                };
                console.log(updateBrand);
                fetch(`https://camerabazar-server.vercel.app/editbrand/${_id}`, {
                    method: "PUT",
                    headers: {
                        "content-type": "application/json",
                    },
                    body: JSON.stringify(updateBrand),
                })
                    .then((res) => res.json())
                    .then((result) => {
                        console.log(result);
                        if (data.acknowledged) {
                            <div>
                                {toast.success("Brand update successfully!")}
                                <Toaster />
                            </div>;
                        }
                        // navigate(from, { replace: true });
                        toast.success(`${data.model} is added successfully`);
                        refetch();
                        reset();
                    });
            });
    };

    const { data: allBrand, isLoading, refetch } = useQuery({
        queryKey: ["allBrand"],
        queryFn: async () => {
            const res = await fetch("https://camerabazar-server.vercel.app/brand");
            const data = res.json();
            return data;
        },
    });

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="grid lg:grid-cols-3 gap-4 m-10">
            <div className="col-span-1 w-full">
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="grid gap-4 rounded-md w-full"
                >
                    <label className="block text-sm font-medium leading-6 text-gray-900">
                        Brand Name
                    </label>
                    <input
                        {...register("brandName", { required: true })}
                        defaultValue={name}
                    // placeholder={name}
                    />
                    {errors.brandName?.type === "required" && (
                        <p role="alert">Brand name is required</p>
                    )}

                    <JoditEditor
                        ref={editor}
                        value={description}
                        config={des}
                        onBlur={(newContent) => setDetails(newContent)}
                    />

                    <label
                        htmlFor="cover-photo"
                        className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        Brand photo
                    </label>
                    <img className="w-20 mx-auto" src={image} alt="brand img" />
                    <div className="col-span-full">
                        <label
                            htmlFor="cover-photo"
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Update Brand photo
                        </label>
                        <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                            <div className="text-center">
                                {imagePreview ? (
                                    <></>
                                ) : (
                                    <PhotoIcon
                                        className="mx-auto h-12 w-12 text-gray-300"
                                        aria-hidden="true"
                                    />
                                )}
                                {imagePreview ? (
                                    <img
                                        className="w-20 mx-auto"
                                        src={imagePreview}
                                        alt="brand img"
                                    />
                                ) : (
                                    <h2 className="font-bold text-gray-400">
                                        Please select image to preview
                                    </h2>
                                )}

                                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                    <label
                                        htmlFor="file-upload"
                                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                    >
                                        <span>Upload a file</span>
                                        {/* <input id="file-upload" name="file-upload" type="file" className="sr-only" /> */}
                                        <input
                                            className="sr-only"
                                            id="file-upload"
                                            {...register("brandImage")}
                                            type="file"
                                            onChange={handleChange}
                                        />
                                    </label>
                                    <p className="pl-1">or drag and drop</p>
                                </div>
                                <p className="text-xs leading-5 text-gray-600">
                                    200px X 200px <span className="font-bold">WEBP</span> or{" "}
                                    <span className="font-bold">PNG</span> format up to 20kb
                                </p>
                            </div>
                        </div>
                    </div>

                    <button
                        className="w-full bg-blue-600 text-white hover:bg-blue-700 rounded-md py-2"
                        type="submit"
                    >
                        Update Brand
                    </button>
                </form>
            </div>
            <div className="mx-auto border p-10 rounded-md cols-span-2 col-span-2 w-full">
                <table className="table-auto min-w-full text-left text-sm font-light">
                    <thead className="border-b font-medium dark:border-neutral-500">
                        <tr>
                            <th>Photo</th>
                            <th>Brand Name</th>
                            <th>Summary</th>
                            <th className="text-center">Product Count</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allBrand.map((brand) => (
                            <BrandTable
                                key={brand._id}
                                brand={brand}
                                refetch={refetch}
                            ></BrandTable>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default EditBrand;
