import React from 'react'
import { Link } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css' // You can also use <link> for styles
// ..
AOS.init()

const MobileCard = ({ mobile }) => {
  //   const [model, thumb_img, price, _id] = mobile()

  return (
    <div
      data-aos="fade-down"
      className="w-[200px] xl:w-[220px] hover:shadow-lg hover:shadow-grey-300 lg:p-4 duration-700 p-2 xl:p-4 rounded-xl grid content-between"
    >
      <Link to={`/product/${mobile._id}`}>
        <img className="mx-auto" src={mobile.thumb_img} alt="mobile" />
      </Link>
      <Link
        to={`/product/${mobile._id}`}
        className="font-bold text-center mt-2"
      >
        {mobile.model}
      </Link>
      <h2 className="text-center font-bold text-orange-500">
        ৳ {mobile.price}
      </h2>
      <div className="mt-4 flex justify-center">
        <button className="w-fit border-solid border-[1px] border-orange-500 bg-orange-500 text-white rounded-[4px] px-[6px] py-[4px] text-[0.75rem] font-bold mr-1">
          Buy Now
        </button>
        <button className="w-fit border-solid border-[1px] border-orange-500 text-orange-500 rounded-[4px] px-[6px] py-[4px] text-[0.75rem] font-semibold">
          Add to Cart
        </button>
      </div>
    </div>
  )
}

export default MobileCard
