import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const SearchResult = ({ product }) => {

    const [url, setUrl_title] = useState();

    useEffect(() => {
        const url_title = product.name
            .replaceAll(' ', '_')
            .replaceAll('/', '@');
        setUrl_title(url_title);

    }, [product.name, url])

    return (
        <Link className='w-full' to={`/productdetails/${url}`}>
            <div className="bg-white border-2 rounded-lg overflow-hidden flex items-center " key={product.id}>
                <img className="w-24 h-24 object-cover p-2" src={product.image} alt={product.name} />
                <div className="px-4 py-2">
                    <h3 className="text-gray-800 font-medium text-xs">{product.name}</h3>
                    <div className="mt-2 text-gray-600 text-sm">${product.price}</div>
                </div>
            </div>
        </Link>
    );
};

export default SearchResult;