import React, { useContext, useState } from "react";
import {
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/solid";

import {
  FaBars,
  FaFacebookF,
  FaInstagram,
  FaSearch,
  FaTools,
  FaUserAlt,
  FaWhatsapp,
  FaYoutube,
  FaBolt,
  FaChartBar,
  FaRegCircle,
  FaStore,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthProvider";

import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import AllMenu from "./AllMenu";

const solutions = [
  {
    name: "Filter",
    description: "Get a better understanding of your traffic",
    href: "#",
    icon: FaRegCircle,
  },
  {
    name: "Hood",
    description: "Speak directly to your customers",
    href: "#",
    icon: CursorArrowRaysIcon,
  },
  {
    name: "Tripods",
    description: "Your customers' data will be safe and secure",
    href: "#",
    icon: FingerPrintIcon,
  },
  {
    name: "Camera Bag",
    description: "Connect with third-party tools",
    href: "#",
    icon: SquaresPlusIcon,
  },
  {
    name: "Camera Flash",
    description: "Build strategic funnels that will convert",
    href: "#",
    icon: FaBolt,
  },
];

const MobileHeader = () => {
  const { user, logOut } = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const handleLogOut = () => {
    logOut()
      .then(() => { })
      .catch((err) => console.log(err));
  };

  // const [open, setOpen] = useState(false);

  return (
    <div className="xl:w-10/12 w-full flex items-center justify-between py-2 mb-0 xl:py-4 mx-auto shadow-md">
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <Dialog.Title className="flex justify-between text-base font-semibold leading-6 text-gray-900">
                          Camera Bazar
                          <div>
                            {user?.uid ? (
                              <div className="flex items-center gap-2">
                                {" "}
                                <FaUserAlt className="icon"></FaUserAlt>
                                <Link
                                  className="px-2 rounded-md bg-blue-600 text-white"
                                  to="/dashboard"
                                >
                                  Dashboard
                                </Link>
                                <button
                                  onClick={handleLogOut}
                                  className="bg-red-100 hover:border-none rounded-md px-2 hover:bg-red-500 hover:text-white border-blue-600 ml-1"
                                >
                                  SignOut
                                </button>
                              </div>
                            ) : (
                              <div className="flex items-center gap-2">
                                <FaUserAlt className="icon"></FaUserAlt>
                                <div>
                                  <h2 className="font-bold text-sm">Account</h2>
                                  <Link to="/signup" className="text-xs mr-1">
                                    Register
                                  </Link>
                                  <span>or</span>

                                  <Link to="/login" className="text-xs ml-1 ">
                                    Login
                                  </Link>
                                </div>
                              </div>
                            )}
                          </div>
                        </Dialog.Title>
                      </div>
                      <hr className="w-full mx-auto mt-4" />

                      <div
                        className="relative mt-6 flex-1 px-4 sm:px-6"
                      // onClick={() => setOpen(false)}
                      >
                        {/* Your content */}
                        <Menu>
                          <MenuItem onClick={() => setOpen(false)}
                            icon={
                              <FaStore
                                className="h-6 w-6 text-gray-600 bottom-1 group-hover:text-indigo-600"
                                aria-hidden="true"
                              />
                            }
                            component={<Link to="/shop" />}
                          >
                            Store (All Products)
                          </MenuItem>
                          <SubMenu
                            icon={
                              <FaTools
                                className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                                aria-hidden="true"
                              />
                            }
                            label="Accessories"
                          >
                            {solutions.map((item) => (
                              <AllMenu key={item.name} item={item}></AllMenu>
                            ))}
                          </SubMenu>

                          <MenuItem
                            icon={
                              <FaChartBar
                                className="h-6 w-6 text-gray-600 bottom-1 group-hover:text-indigo-600"
                                aria-hidden="true"
                              />
                            }
                            component={<Link to="/dashboard" />}
                          >
                            Dashboard
                          </MenuItem>
                        </Menu>

                        {/* Footer Start*/}
                        <div className="absolute bottom-0 w-[95%] mx-auto">
                          <hr className="w-[90%] mx-auto mb-4" />
                          <div className="flex justify-center gap-2 text-4xl text-gray-600 mx-auto">
                            <FaFacebookF className="border p-1 rounded-md text-[#4267B2]" />
                            <FaYoutube className="border p-1 rounded-md text-[#FF0000]" />
                            <FaInstagram className="border p-1 rounded-md text-white bg-gradient-to-b from-indigo-500 via-purple-500 to-pink-500" />
                            <Link
                              target="_blank"
                              to="https://wa.me/+8801917256756"
                            >
                              <FaWhatsapp className="border p-1 rounded-md text-[#28D146]" />
                            </Link>
                          </div>
                        </div>
                        {/* Footer End */}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div
        className="lg:hidden menu-trigger pl-4"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <FaBars className="text-3xl"></FaBars>
      </div>

      <Link to="/">
        <img
          className="w-[70px] md:w-28 lg:hidden "
          src="https://i0.wp.com/camerabazar.net/wp-content/uploads/2021/01/logo-transparant-e1610344962382.png?fit=212%2C112&ssl=1"
          alt=""
        />
      </Link>
      <FaSearch className="icon lg:hidden mr-4"></FaSearch>
    </div>
  );
};

export default MobileHeader;
