// import { useQuery } from '@tanstack/react-query'
import React from "react";
import { useLoaderData } from "react-router-dom";
import FormatPrice from "../Helpers/FormatPrice";
// import Loading from '../Loading'

const ProductDetails = () => {
  const product = useLoaderData();
  const { model, brand, thumb_img, price } = product;

  return (
    <div className="grid grid-cols-2 my-10">
      <div className="">
        <img className="mx-auto" src={thumb_img} alt="Images" />
      </div>
      <div>
        <h2 className="text-xl font-bold">{model}</h2>
        <p className="text-slate-600 border-2 w-fit px-2 py-1 border-b-orange-600 rounded-lg">
          {brand}
        </p>
        <h2 className="text-md font-medium">{<FormatPrice price={price} />}</h2>
      </div>
    </div>
  );
};

export default ProductDetails;
