import DOMPurify from "dompurify";
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { cartData } from '../../layout/Main';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import FormatPrice from "../Helpers/FormatPrice";


// ..
AOS.init();

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600',
    bgcolor: 'background.paper',
    // border: '2px solid #000',//
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    img: {
        width: '400px'
    }

};

const QuickView = ({ open, setOpen, handleClose, mobile }) => {
    // const [open, setOpen] = useState(false);
    const { name, image, price, salesPrice, category, brand, shortDes } = mobile;
    const summary = DOMPurify.sanitize(mobile?.shortDes);
    console.log(summary);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Box sx={style}>
                <img src={image} alt={name} />
                <Box>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {name}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ marginY: 2, fontWeight: 500, fontSize: 16 }}>
                        {<FormatPrice price={price} />}
                    </Typography>
                    <div dangerouslySetInnerHTML={{ __html: summary }}>

                    </div>
                </Box>
            </Box>

        </Modal>
    );
};

export default QuickView;