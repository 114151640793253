import React from 'react';

const AllMenu = ({ item }) => {
    return (
        <div className="group relative items-center flex gap-x-6 rounded-lg px-4 py-[2px] hover:bg-gray-50">
            <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
            </div>
            <div>
                <a href={item.href} className="font-semibold text-gray-900">
                    {item.name}
                    <span className="absolute inset-0" />
                </a>
            </div>
        </div>
    );
};

export default AllMenu;