import React from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();

const HomeFirstBanner = () => {
  return (
    <div className="grid grid-cols-2 gap-2 xl:gap-4 w-11/12 xl:w-10/12 mx-auto pb-4 xl:py-14">
      <img
        data-aos="fade-down"
        data-aos-offset="500"
        data-aos-duration="500"
        src="https://adminapi.applegadgetsbd.com/storage/media/large/Speaker_01-1674.jpg"
        alt="banner"
      />
      <img
        data-aos="fade-down"
        data-aos-offset="500"
        data-aos-duration="500"
        src="https://adminapi.applegadgetsbd.com/storage/media/large/Speaker_02-3429.jpg"
        alt="banner"
      />
    </div>
  );
};

export default HomeFirstBanner;
