import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthProvider";
import { FaGoogle } from "react-icons/fa";
import toast from "react-hot-toast";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// import { stringify } from "@firebase/util";
// ..
AOS.init();

const Login = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const { signIn } = useContext(AuthContext);
  const [loginError, setLoginError] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const from = location.state?.from?.pathname || "/";

  const handleLogin = (data) => {
    setLoginError("");
    console.log(data);
    signIn(data.email, data.password)
      .then((result) => {
        const user = result.user;

        const currentUser = {
          email: user.email,
        };
        console.log(currentUser);

        toast.success("Login Successfully!");
        navigate(from, { replace: true });
        // Get jwt token
        fetch("https://camerabazar-server.vercel.app/jwt", {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(currentUser),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            localStorage.setItem("cb-token", data.token);
          });
      })
      .catch((error) => {
        console.log(error.message);
        setLoginError(error.message);
      });
  };

  return (
    <div
      data-aos="fade-down"
      className="lg:w-96 w-fit md:mx-auto mx-4 p-6 border my-10 rounded-lg shadow-lg"
    >
      <h2 className="font-bold bg-white w-fit border rounded-md px-4 py-2 relative top-[-36px]">
        User Login
      </h2>
      <form onSubmit={handleSubmit(handleLogin)}>
        <div className="grid grid-cols-1">
          <label className="text-gray-600">Email address</label>
          <input
            className="shadow-inner shadow-gray-400 rounded-md bg-white"
            {...register("email", { required: "Email is required" })}
            placeholder="Email"
            type="email"
          />
          {errors.email && (
            <p className="text-red-500" role="alert">
              {errors.email?.message}
            </p>
          )}
        </div>
        <div className="grid grid-cols-1 mt-4">
          <label className="text-gray-600">Password</label>
          <input
            className="shadow-inner shadow-gray-400 rounded-md bg-white"
            {...register("password", {
              required: "Password is required",
              minLength: {
                value: 6,
                message: "Password must be minimum 6 characters",
              },
            })}
            placeholder="Password"
            type="password"
          />
          {errors.password && (
            <p className="text-red-500" role="alert">
              {errors.password?.message}
            </p>
          )}
          <label className="text-gray-600">Forgot Password</label>
        </div>
        <input
          className="w-full bg-orange-500 mt-4 text-white rounded-md font-bold uppercase cursor-pointer"
          type="submit"
          value="Login"
        />
        <div>
          {loginError && <p className="text-orange-600">{loginError}</p>}
        </div>
      </form>
      <button className="grid mx-auto justify-center w-full bg-blue-600 text-white my-4 outline outline-1 py-1 rounded-md outline-slate-500 hover:bg-blue-800 hover:text-white hover:outline-none ">
        <div className="flex items-center">
          <FaGoogle className="mr-2" />
          <p>Continue with Google</p>
        </div>
      </button>
      <hr className="my-4" />
      <Link to="/signup">
        New on Camera Bazar?{" "}
        <span className="font-bold">Create new account</span>
      </Link>
    </div>
  );
};

export default Login;
