import React from 'react';

const Hero = () => {
    return (
        <div className='flex gap-2 lg:gap-4 lg:mx-auto mx-2 xl:mt-0 mt-4'>
            <img className='w-8/12' src="https://adminapi.applegadgetsbd.com/storage/media/large/iPhone-14-Pro-Max-9001.png" alt="" />
            <div className='w-[43%] grid gap-4'>
                <img src="https://adminapi.applegadgetsbd.com/storage/media/large/Airpods-(1)-1361.jpg" alt="" />
                <img src="https://adminapi.applegadgetsbd.com/storage/media/large/SmartWatch-(1)-2459.jpg" alt="" />
            </div>
        </div>
    );
};

export default Hero;