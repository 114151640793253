import { useQuery } from "@tanstack/react-query";
import React from "react";
import Loading from "../../../Components/Loading";
import LatestProductCard from "../LatestProduct/LatestProductCard";

const FeaturedProducts = () => {
  const { data: allMobile, isLoading } = useQuery({
    queryKey: ["allMobile"],
    queryFn: async () => {
      const res = await fetch("https://camerabazar-server.vercel.app/allproducts");
      const data = res.json();
      return data;
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="mb-10">
      <h2 className="text-2xl font-bold uppercase text-center">
        Featured Products
      </h2>
      <p className="text-center">
        Get your desired product from featured category
      </p>
      <div className="w-full md:w-10/12 lg:w-10/12 xl:w-10/12 xl:my-10 my-4 mx-auto">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 justify-items-center mx-auto">
          {allMobile.map((mobile) => (
            <LatestProductCard key={mobile._id} mobile={mobile} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturedProducts;
