import LatestProductDetails from "../../Components/ProductDetails/LatestProductDetails";
import ProductDetails from "../../Components/ProductDetails/ProductDetails";
import AllProducts from "../../Dashboard/AllProducts/AllProducts";
import Dashboard from "../../Dashboard/Dashboard/Dashboard";
import DashboardLayout from "../../layout/DashboardLayout";
import Main from "../../layout/Main";
import AddBrand from "../../Pages/Brand/AddBrand/AddBrand";
import EditBrand from "../../Pages/Brand/AddBrand/EditBrand";
import AddCategory from "../../Pages/Category/AddCategory/AddCategory";
import EditCategory from "../../Pages/Category/AddCategory/EditCategory";
import Home from "../../Pages/Home/Home";
import AddProduct from "../../Pages/Home/Products/AddProduct";
import SubmitProduct from "../../Pages/Home/Products/SubmitProduct";
import Login from "../../Pages/Login/Login";
import Shop from "../../Pages/Shop/Shop/Shop";
import SignUp from "../../Pages/SignUp/SignUp";
import AdminRoute from "../PrivateRoute/AdminRoute";
import PrivateRoute from "../PrivateRoute/PrivateRoute";

const { createBrowserRouter } = require("react-router-dom");

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main></Main>,
    children: [
      {
        path: "/",
        element: <Home></Home>,
      },
      {
        path: "/shop",
        element: <Shop></Shop>,
      },
      {
        path: "/product/:id",
        element: <ProductDetails />,
        loader: ({ params }) =>
          fetch(`https://camerabazar-server.vercel.app/product/${params.id}`),
      },
      {
        path: "/productdetails/:id",
        element: <LatestProductDetails />,
        loader: ({ params }) =>
          fetch(
            `https://camerabazar-server.vercel.app/productdetails/${params.id}`
            // `http://localhost:4000/productdetails/${params.id}`
          ),
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <SignUp />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: (
      <PrivateRoute>
        <DashboardLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: "/dashboard",
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
      },
      {
        path: "/dashboard/addproduct",
        element: (
          <AdminRoute>
            <AddProduct></AddProduct>
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/submitProduct",
        element: (
          <AdminRoute>
            <SubmitProduct />
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/addcategory",
        element: (
          <AdminRoute>
            <AddCategory />
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/addbrand",
        element: (
          <AdminRoute>
            <AddBrand />
          </AdminRoute>
        ),
      },
      {
        path: "/dashboard/editcategory/:id",
        element: (
          <AdminRoute>
            <EditCategory />
          </AdminRoute>
        ),
        loader: ({ params }) =>
          fetch(`https://camerabazar-server.vercel.app/category/${params.id}`),
      },
      {
        path: "/dashboard/editbrand/:id",
        element: (
          <AdminRoute>
            <EditBrand />
          </AdminRoute>
        ),
        loader: ({ params }) =>
          fetch(`https://camerabazar-server.vercel.app/brand/${params.id}`),
      },
      {
        path: "/dashboard/allproducts",
        element: <AllProducts />,
      },
    ],
  },
]);

export default router;
