import { Toaster } from "react-hot-toast";
import { ProSidebarProvider } from "react-pro-sidebar";
import { RouterProvider } from "react-router-dom";
import "./App.css";
import router from "./Router/Router/Router";


function App() {
  return (
    <div className="bg-white">
      <ProSidebarProvider>
        <RouterProvider router={router} />
        <Toaster />
      </ProSidebarProvider>
    </div>
  );
}

export default App;
