import React from 'react'
import phones from '../../../Assets/Category-Icon/Phones-Tablets.png'
import adapter from '../../../Assets/Category-Icon/power-adapter.png'
import macbook from '../../../Assets/Category-Icon/Macbook.png'
import smartWatch from '../../../Assets/Category-Icon/Smart-Watch.png'
import airpods from '../../../Assets/Category-Icon/airpods.png'
import overheadHeadphone from '../../../Assets/Category-Icon/Overhead-Headphone.png'
import speakers from '../../../Assets/Category-Icon/Speaker.png'
import wiredHeadphone from '../../../Assets/Category-Icon/Wired-Headphone.png'
import wirelessHeadphone from '../../../Assets/Category-Icon/Wireless-Headphone.png'
import cableInterconnection from '../../../Assets/Category-Icon/Cable-&-interconnects.png'
import powerBank from '../../../Assets/Category-Icon/power-bank.png'
import wirelessCharger from '../../../Assets/Category-Icon/Wireless-Charger.png'
import hubs from '../../../Assets/Category-Icon/Hubs-Dock.png'
import keyboard from '../../../Assets/Category-Icon/keyboard.png'
import coverGlass from '../../../Assets/Category-Icon/Cover-&-Glass.png'
import droneCamera from '../../../Assets/Category-Icon/Drone-&-Camera.png'

const FeaturedCategories = () => {
    return (
        <div className="py-10 w-10/12 xl:w-10/12 mx-auto">
            <h2 className="text-2xl font-bold uppercase text-center">
                Featured Categories
            </h2>
            <p className="text-center">
                Get your desired product from featured category
            </p>
            <div className="w:full xl:w-12/12 grid grid-cols-4 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 justify-items-center gap-4 py-10 px-4 mx-auto">
                <div className="category-box">
                    <div>
                        <img src={phones} alt="Phones & Tablet" />
                        <h2 className="text-center">Phones & Tablets</h2>
                    </div>
                </div>
                <div className="category-box">
                    <div>
                        <img src={adapter} alt="Power Adapter" />
                        <h2 className="text-center">Power Adapter</h2>
                    </div>
                </div>
                <div className="category-box">
                    <div>
                        <img src={macbook} alt="MacBook" />
                        <h2 className="text-center">MacBook</h2>
                    </div>
                </div>
                <div className="category-box">
                    <div>
                        <img src={smartWatch} alt="Smart Watch" />
                        <h2 className="text-center">Smart Watch</h2>
                    </div>
                </div>
                <div className="category-box">
                    <div>
                        <img src={airpods} alt="airpods" />
                        <h2 className="text-center">Airpods</h2>
                    </div>
                </div>
                <div className="category-box">
                    <div>
                        <img src={overheadHeadphone} alt="Overhead Headphone" />
                        <h2 className="text-center">Overhead Headphones</h2>
                    </div>
                </div>
                <div className="category-box">
                    <div>
                        <img src={speakers} alt="Speakers" />
                        <h2 className="text-center">Speakers</h2>
                    </div>
                </div>
                <div className="category-box">
                    <div>
                        <img src={wiredHeadphone} alt="Wired Headphone" />
                        <h2 className="text-center">Wired Headphone</h2>
                    </div>
                </div>
                <div className="category-box">
                    <div>
                        <img src={wirelessHeadphone} alt="Wireless Headphone" />
                        <h2 className="text-center">Wireless Headphone</h2>
                    </div>
                </div>
                <div className="category-box">
                    <div>
                        <img src={cableInterconnection} alt="Cable & Interconnection" />
                        <h2 className="text-center">Cable & Interconnects</h2>
                    </div>
                </div>
                <div className="category-box">
                    <div>
                        <img src={powerBank} alt="Power Bank" />
                        <h2 className="text-center">Power Bank</h2>
                    </div>
                </div>
                <div className="category-box">
                    <div>
                        <img src={wirelessCharger} alt="Wireless Charger" />
                        <h2 className="text-center">Wireless Charger</h2>
                    </div>
                </div>
                <div className="category-box">
                    <div>
                        <img src={hubs} alt="hubs icon" />
                        <h2 className="text-center">Hubs & Dock</h2>
                    </div>
                </div>
                <div className="category-box">
                    <div>
                        <img src={keyboard} alt="Keyboard" />
                        <h2 className="text-center">Keyboard</h2>
                    </div>
                </div>
                <div className="category-box">
                    <div>
                        <img src={coverGlass} alt="Cover & Glass" />
                        <h2 className="text-center">Cover & Glass</h2>
                    </div>
                </div>
                <div className="category-box">
                    <div>
                        <img src={droneCamera} alt="Drone & Camera Accessories" />
                        <h2 className="text-center">Drone & Camera Accessories</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeaturedCategories
