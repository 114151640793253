import React, { useContext } from "react";
import {
  FaGift,
  FaSearch,
  FaShoppingCart,
  FaStore,
  FaUserAlt,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthProvider";
import MobileHeader from "./MobileHeader";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { IconButton } from "@mui/material";
import InsertChartIcon from '@mui/icons-material/InsertChart';
import LogoutIcon from '@mui/icons-material/Logout';
import ProductSearch from "./ProductSearch/ProductSearch";


const Header = () => {
  const { user, logOut } = useContext(AuthContext);

  const handleLogOut = () => {
    logOut()
      .then(() => { })
      .catch((err) => console.log(err));
  };

  // const [open, setOpen] = useState(false);

  return (
    <div className="bg-slate-100 ">
      <div className="lg:hidden">
        <MobileHeader></MobileHeader>
      </div>
      <div className="xl:w-10/12 w-full flex items-center justify-between py-0 xl:py-4 mx-auto">
        <Link to="/">
          <img
            className="lg:w-[260px] md:w-20 lg:block hidden "
            src="https://i0.wp.com/camerabazar.net/wp-content/uploads/2021/01/logo-transparant-e1610344962382.png?fit=212%2C112&ssl=1"
            alt=""
          />
        </Link>
        {/* <div className="lg:hidden pl-4">
            <FaBars className="text-3xl"></FaBars>
          </div> */}
        <div className="lg:w-full lg:mx-10">
          <ProductSearch />
        </div>
        <div className="lg:w-full">
          <div className="lg:flex gap-4 justify-between hidden">
            <Link to="/shop" className="flex items-center gap-2">
              <IconButton>
                <StorefrontIcon></StorefrontIcon>
              </IconButton>
              <div>
                <h2 className="font-bold text-sm">Shop</h2>
                <p className="text-xs">All Products</p>
              </div>
            </Link>
            <div className="flex items-center gap-2">
              <FaGift className="icon"></FaGift>
              <div>
                <h2 className="font-bold text-sm">Offers</h2>
                <p className="text-xs">Latest Offers</p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <FaShoppingCart className="icon"></FaShoppingCart>
              <div>
                <h2 className="font-bold text-sm">Cart(0)</h2>
                <p className="text-xs">Add items</p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <FaStore className="icon"></FaStore>
              <div>
                <h2 className="font-bold text-sm">Pre-Order</h2>
                <p className="text-xs">Order Today</p>
              </div>
            </div>
            {user?.uid ? (
              <div className="flex items-center gap-2">
                {" "}
                {/* <FaUserAlt className="icon"></FaUserAlt>
                <Link
                  className="px-2 rounded-md bg-blue-600 text-white"
                  to="/dashboard"
                >
                  Dashboard
                </Link> */}
                <Link to='/dashboard'> <IconButton aria-label="fingerprint" color="secondary">
                  <InsertChartIcon />
                </IconButton></Link>
                {/* <button
                  onClick={handleLogOut}
                  className="bg-red-100 hover:border-none rounded-md px-2 hover:bg-red-500 hover:text-white border-blue-600 ml-1"
                >
                  SignOut
                </button> */}
                <IconButton>
                  <LogoutIcon onClick={handleLogOut}></LogoutIcon>
                </IconButton>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <FaUserAlt className="icon"></FaUserAlt>
                <div>
                  <h2 className="font-bold text-sm">Account</h2>
                  <Link to="/signup" className="text-xs mr-1">
                    Register
                  </Link>
                  <span>or</span>

                  <Link to="/login" className="text-xs ml-1">
                    Login
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="lg:flex gap-4 bg-black py-2 text-sky-100 justify-center hidden">
        <h2>Phones & Tablet</h2>
        <h2>Laptop & Desktop</h2>
        <h2>Sound Equipment</h2>
        <h2>Power & Accessories</h2>
        <h2>Fitness & Wearable</h2>
        <h2>Peripherals</h2>
        <h2>Cover & Glass</h2>
        <h2>Media Streamers</h2>
        <h2>Smart Electronics</h2>
        <h2>Combo</h2>
      </div>
    </div>
  );
};

// function DropdownItem(props) {
//   return (
//     <li className="dropdownItem">
//       <img src={props.img}></img>
//       <a>{props.text}</a>
//     </li>
//   );
// }

export default Header;
