import JoditEditor from "jodit-react";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../Components/Loading";
import { useQuery } from "@tanstack/react-query";
import { PhotoIcon } from "@heroicons/react/24/solid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/material";

const SubmitProduct = ({ placeholder }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const editor = useRef(null);
  const [shortDes, setShortDes] = useState("");
  const [longDes, setLongDes] = useState("");
  const [imagePreview, setImagePreview] = useState();
  const imageKey = process.env.REACT_APP_imageBB_key;

  const from = location.state?.from?.pathname || "/dashboard";

  const shortText = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: placeholder || "Product short description...",
  };
  const longTex = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: placeholder || "Product long description...",
  };

  function handleChange(e) {
    console.log(e.target.files);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { data: allCategory = [], isLoading } = useQuery({
    queryKey: ["allCategory"],
    queryFn: async () => {
      const res = await fetch("https://camerabazar-server.vercel.app/category");
      const data = res.json();
      console.log(data);
      return data;
    },
  });

  const { data: allBrand = [] } = useQuery({
    queryKey: ["allBrand"],
    queryFn: async () => {
      const res = await fetch("https://camerabazar-server.vercel.app/brand");
      const data = res.json();
      console.log(data);
      return data;
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  const onSubmit = (data) => {
    console.log(data);
    const image = data.thumbImage[0];
    const formData = new FormData();
    formData.append("image", image);
    const url = `https://api.imgbb.com/1/upload?key=${imageKey}`;
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((imgData) => {
        console.log(imgData.data.url);
        const addProduct = {
          name: data.productName,
          category: data.category,
          brand: data.brand,
          price: data.price,
          salesPrice: data.salesPrice,
          stock: data.stock,
          image: imgData.data.url,
          shortDes: shortDes,
          longDes: longDes,
          submissionTime: new Date().getTime(),
        };
        console.log(addProduct);
        fetch("https://camerabazar-server.vercel.app/addproduct", {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(addProduct),
        })
          .then((res) => res.json())
          .then((result) => {
            console.log(result);
            if (data.acknowledged) {
              <div>
                {toast.success("Mobile added successfully!")}
                <Toaster />
              </div>;
            }
            navigate(from, { replace: true });
            toast.success(`${data.productName} is added successfully`);
          });
      });
  };

  return (
    <div className="m-6">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full">
          {/* <label
            htmlFor="productName"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Product Name
          </label>
          <input
            className="col-span-full w-full rounded-md"
            {...register("productName", { required: true })}
            placeholder="Product Name"
          />
          {errors.productName?.type === "required" && (
            <p className="text-red-600" role="alert">
              Product name is required
            </p>
          )} */}

          <TextField
            variant="outlined"
            label="Product Name"
            fullWidth
            autoComplete="productName"
            autoFocus
            {...register("productName", {
              required: "Product Name Required",
              message: "Product name is required",
            })}
            error={!!errors?.productName}
            helperText={errors?.productName ? errors.productName.message : null}
          />
        </div>
        {/* Test */}

        {/* Test End */}

        <div className="flex mt-4 gap-4">
          <div className="w-8/12">
            <JoditEditor
              ref={editor}
              value={shortDes}
              config={shortText}
              onBlur={(newContent) => setShortDes(newContent)}
            />

            <JoditEditor
              ref={editor}
              value={longDes}
              config={longTex}
              onBlur={(newContent) => setLongDes(newContent)}
            />
          </div>
          <div className="gap-2 mb-2 w-4/12">
            <Box>
              <Autocomplete
                name="category"
                inputRef={register}
                autoFocus
                options={allCategory.map((e) => e.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="category"
                    value={params}
                    label="Select Category"
                  />
                )}
                {...register("category", {
                  required: "Please enter a valid category",
                })}
                error={!!errors?.category}
                helperText={errors?.category ? errors.category.message : null}
              />
            </Box>
            {/* <Box>
              <Autocomplete
                name="category"
                // inputRef={register}
                autoHighlight
                options={allCategory.map((e) => e.name)}
                getOptionLabel={(option) => option.label}
                 renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={"https://i.ibb.co/SJzt1P4/nikon-logo.webp"}
                      alt=""
                    />
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="category"
                    value={params}
                    label="Select Category"
                  />
                )}
                {...register("category", {
                  required: "Please enter a valid category",
                })}
                error={!!errors?.category}
                helperText={errors?.category ? errors.category.message : null}
              />
            </Box> */}

            {/* <label
                htmlFor="country"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Category
              </label> */}

            {/* <select
                  {...register("category", { required: true })}
                  className="select select-bordered w-full border p-2 rounded-md"
                >

                  {allCategory.map((category) => (
                    <option key={category._id} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </select> */}

            {/* <Stack spacing={2}>
                  <Autocomplete
                    fullWidth
                    // calling the freeSolo prop inside the Autocomplete component
                    freeSolo
                    id='category'
                    name="category"
                    inputRef={register}
                    options={allCategory.map((option) => option.name)}
                    renderInput={(params) => (
                      <TextField {...params} label="Category" />
                    )}
                  />
                </Stack> */}

            <Box my={2}>
              <Autocomplete
                name="brand"
                inputRef={register}
                autoFocus
                options={allBrand.map((e) => e.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="brand"
                    value={params}
                    label="Select brand"
                  />
                )}
                {...register("brand", {
                  required: "Please enter a valid brand",
                })}
                error={!!errors?.brand}
                helperText={errors?.brand ? errors.brand.message : null}
              />
            </Box>

            {/* <div>
              <label
                htmlFor="brand"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Brand Name
              </label>
              <input
                className="col-span-full w-full rounded-md"
                {...register("brand", { required: true })}
                placeholder="Brand Name"
              />
              {errors.brand?.type === "required" && (
                <p className="text-red-600" role="alert">
                  Brand name is required
                </p>
              )}
            </div> */}

            <div>
              {/* <label
                htmlFor="price"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Price
              </label>
              <input
                className="col-span-full w-full rounded-md"
                {...register("price", { required: true })}
                placeholder="Price in BDT"
              />
              {errors.price?.type === "required" && (
                <p className="text-red-600" role="alert">
                  Price is required
                </p>
              )} */}
              <Box my={2}>
                <TextField
                  variant="outlined"
                  label="Price"
                  fullWidth
                  type="number"
                  autoComplete="price"
                  autoFocus
                  {...register("price", { required: "Price Required" })}
                  error={!!errors?.price}
                  helperText={errors?.price ? errors.price.message : null}
                />
              </Box>
            </div>
            <div>
              {/* <label
                htmlFor="salesPrice"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Sales Price (With discount)
              </label>
              <input
                className="col-span-full w-full rounded-md"
                {...register("salesPrice")}
                placeholder="Discount Price in BDT"
              /> */}
              <Box my={2}>
                <TextField
                  variant="outlined"
                  label="Sales Price (With Discount)"
                  fullWidth
                  type="number"
                  autoComplete="salesPrice"
                  autoFocus
                  {...register("salesPrice")}
                />
              </Box>
            </div>
            <div>
              {/* <label
                htmlFor="salesPrice"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Stock Qty
              </label>
              <input
                className="col-span-full w-full rounded-md"
                {...register("stock", { required: true })}
                placeholder="Stock QTY"
              />
              {errors.stock?.type === "required" && (
                <p className="text-red-600" role="alert">
                  Stock quantity is required
                </p>
              )} */}

              <Box my={2}>
                <TextField
                  variant="outlined"
                  label="Stock Qty"
                  fullWidth
                  type="number"
                  autoComplete="stock"
                  autoFocus
                  {...register("stock", { required: "Stock QTY Required" })}
                  error={!!errors?.stock}
                  helperText={errors?.stock ? errors.stock.message : null}
                />
              </Box>

              <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25">
                <div className="text-center">
                  {imagePreview ? (
                    <></>
                  ) : (
                    <PhotoIcon
                      className="mx-auto h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                  )}
                  {imagePreview ? (
                    <img
                      className="w-40 mx-auto"
                      src={imagePreview}
                      alt="category img"
                    />
                  ) : (
                    <h2 className="font-bold text-gray-400">
                      Please select image to preview
                    </h2>
                  )}

                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span>Upload a file</span>
                      {/* <input id="file-upload" name="file-upload" type="file" className="sr-only" /> */}
                      <input
                        className="sr-only"
                        id="file-upload"
                        {...register("thumbImage", { required: true })}
                        type="file"
                        onChange={handleChange}
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs leading-5 text-gray-600 mb-3">
                    200px X 200px webp format up to 20kb
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {errors.mail && <p role="alert">{errors.mail?.message}</p>} */}
        {/* <div className="form-control w-full max-w-xs ">
          <label className="label">
            {" "}
            <span className="label-text">Photo</span>
          </label>
          <input
            type="file"
            {...register("image", {
              required: "Photo is required",
            })}
            className="input input-bordered w-full max-w-xs"
          />
          {errors.img && <p className="text-red-600">{errors.img.message}</p>}
        </div> */}
        <input
          className="border px-4 mt-4 rounded-md hover:bg-sky-600 hover:text-white cursor-pointer"
          type="submit"
        />
      </form>
    </div>
  );
};

export default SubmitProduct;
