import React from 'react';

const HomeSecondBanner = () => {
    return (
        <div className="grid grid-cols-4 md:grid-cols-4 xl:grid-cols-3 gap-2 xl:gap-4 w-11/12 xl:w-10/12 mx-auto py-10">
            <img className="w-full col-span-4 xl:col-span-1  mx-auto" src="https://adminapi.applegadgetsbd.com/storage/media/large/Bottom-Banner-February-12_01-2714.jpg" alt="banner" />
            <img className="col-span-2 md:col-span-2 xl:col-span-1" src="https://adminapi.applegadgetsbd.com/storage/media/large/Bottom-Banner-February-12_02-8409.jpg" alt="banner" />
            <img className="col-span-2 md:col-span-2 xl:col-span-1" src="https://adminapi.applegadgetsbd.com/storage/media/large/Bottom-Banner-February-12_03-3833.jpg" alt="banner" />
        </div>
    );
};

export default HomeSecondBanner;