import { Typography } from '@mui/material';
import { Box, Container, padding } from '@mui/system';
import React from 'react';

const Dashboard = () => {
    return (
        <Container>
            <Box
                sx={
                    {
                        margin: '10px',
                        display: 'flex',
                    }
                }
            >
                <Box
                    sx={
                        {
                            border: "1px solid red",
                            margin: '10px',
                            padding: '20px',
                            borderRadius: '10px',
                            transition: "300ms ease-in-out",
                            "&:hover": {
                                backgroundColor: "#F0F0F0",
                            }
                        }
                    }
                >
                    <Typography variant='p' >Order Received</Typography>
                    <Typography variant='h3'>250</Typography>
                </Box>
                <Box
                    sx={
                        {
                            border: "1px solid red",
                            margin: '10px',
                            padding: '20px',
                            borderRadius: '10px',
                        }
                    }
                >
                    <Typography variant='p' >Order Received</Typography>
                    <Typography variant='h3'>250</Typography>
                </Box>
            </Box>
        </Container>
    );
};

export default Dashboard;