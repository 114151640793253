import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { FaGoogle } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthProvider'
import AOS from 'aos'
import 'aos/dist/aos.css' // You can also use <link> for styles
// ..
AOS.init()

const SignUp = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const { createUser, updateUser } = useContext(AuthContext)
  const [signUpError, setSignUpError] = useState('')

  const handleSignup = (data) => {
    setSignUpError('')
    console.log(data)
    createUser(data.email, data.password)
      .then((result) => {
        const user = result.user
        console.log(user)
        toast.success('User Created Successfully!')
        const userInfo = {
          displayName: data.name,
        }
        updateUser(userInfo)
          .then(() => {})
          .catch((err) => console.log(err))
      })
      .catch((error) => {
        console.log(error)
        setSignUpError(error.message)
      })
  }

  return (
    <div
      data-aos="fade-down"
      className="lg:w-96 w-fit md:mx-auto mx-4 p-6 border my-10 rounded-lg shadow-lg"
    >
      <h2 className="font-bold bg-white w-fit border rounded-md px-4 py-2 relative top-[-36px]">
        Signup
      </h2>
      <form onSubmit={handleSubmit(handleSignup)}>
        <div className="grid grid-cols-1">
          <label className="text-gray-600">Full Name</label>
          <input
            className="shadow-inner shadow-gray-400 rounded-md bg-white"
            {...register('name', { required: 'Name is required' })}
            placeholder="Full name"
            type="text"
          />
          {errors.email && (
            <p className="text-red-500" role="alert">
              {errors.email?.message}
            </p>
          )}
        </div>
        <div className="grid grid-cols-1 mt-4">
          <label className="text-gray-600">Email address</label>
          <input
            className="shadow-inner shadow-gray-400 rounded-md bg-white"
            {...register('email', { required: 'Email is required' })}
            placeholder="Email"
            type="email"
          />
          {errors.email && (
            <p className="text-red-500" role="alert">
              {errors.email?.message}
            </p>
          )}
        </div>
        <div className="grid grid-cols-1 mt-4">
          <label className="text-gray-600">Password</label>
          <input
            className="shadow-inner shadow-gray-400 rounded-md bg-white"
            {...register('password', {
              required: 'Password is required',
              minLength: {
                value: 6,
                message: 'Password must be minimum 6 characters',
              },
              pattern: {
                value: /[A-Z]{1}/ && /[0-9]{1}/ && /(?=.*?[#?!@$%^&*-]){1}/,
                message:
                  'Password need combination with this - Minimum one Upper case & one lower case letters also one sumbol',
              },
            })}
            placeholder="Password"
            type="password"
          />
          {errors.password && (
            <p className="text-red-500" role="alert">
              {errors.password?.message}
            </p>
          )}
        </div>
        <input
          className="w-full bg-orange-500 mt-4 text-white rounded-md font-bold uppercase cursor-pointer"
          type="submit"
          value="Signup"
        />
        <div>
          {signUpError && <p className="text-orange-600">{signUpError}</p>}
        </div>
      </form>
      <button className="grid mx-auto justify-center w-full bg-blue-600 text-white my-4 outline outline-1 py-1 rounded-md outline-slate-500 hover:bg-blue-800 hover:text-white hover:outline-none ">
        <div className="flex items-center">
          <FaGoogle className="mr-2" />
          <p>Continue with Google</p>
        </div>
      </button>
      <hr className="my-4" />
      <Link to="/login">
        Already have an account?{' '}
        <span className="font-bold">Login from here</span>
      </Link>
    </div>
  )
}

export default SignUp
