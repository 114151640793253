import React, { useContext } from "react";
import { FaAngleLeft } from "react-icons/fa";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthProvider";
import useAdmin from "../../hooks/useAdmin";
import { } from "@heroicons/react/24/solid";
import { HiBuildingStorefront } from "react-icons/hi2";
import './SideBar.css'



const SideBar = () => {
  const { collapseSidebar } = useProSidebar();
  const { user } = useContext(AuthContext)
  const [isAdmin] = useAdmin(user?.email)
  return (
    <div className="min-h-screen shadow-md bg-[#FBFBFB]">
      <Sidebar>
        <img
          className="w-16 mx-auto rounded-full border-4 border-white shadow-md"
          src="https://i.ibb.co/ZRrfbBF/user.webp"
          alt="User profile_image"
        />
        <h2 className="text-center">
          Welcome, <span className="font-bold my-2">Admin</span>
        </h2>
        <main>
          <button
            className="flex justify-end items-center"
            onClick={() => collapseSidebar()}
          >
            <FaAngleLeft />
            Clops
          </button>
        </main>
        <hr />
        <Menu>
          <SubMenu label="Charts">
            <MenuItem className="hover:text-black"> Pie charts </MenuItem>
            <MenuItem> Line charts </MenuItem>
          </SubMenu>
          {
            isAdmin && <>
              <SubMenu label="Products" icon={<HiBuildingStorefront className="menu-icon" />}>
                <MenuItem component={<Link to="/dashboard/submitproduct" />}>
                  Add Product
                </MenuItem>
                <MenuItem component={<Link to="/dashboard/addcategory" />}>
                  Add Category
                </MenuItem>
                <MenuItem component={<Link to="/dashboard/addbrand" />}>
                  Add Brand
                </MenuItem>
              </SubMenu>
            </>
          }
          <MenuItem component={<Link to="/dashboard/allproducts" />}>
            All Products
          </MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default SideBar;
