import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Divider,
    IconButton,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FormatPrice from "../../../Components/Helpers/FormatPrice";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

const SliderCard = ({ mobile }) => {
    const { name, image, price, salesPrice } = mobile;
    const [url, setUrl_title] = useState();

    useEffect(() => {
        // const urlRegex = /\s/g;
        // const forSlash = /\//g;
        // const url_title = name.replace(forSlash, '@' & urlRegex, '_');
        const url_title = name
            .replaceAll(' ', '_')
            .replaceAll('/', '@');
        setUrl_title(url_title);

    }, [name, url])

    return (
        <Card
            sx={{
                marginY: 3,
                height: 300,
                width: {
                    xs: 160,
                    sm: 200,
                    md: 200,
                    lg: 220,
                    xl: 220,
                },
                display: "grid",
                alignContent: "space-between",

            }}
        >
            <Link to={`/productdetails/${url}`}>
                <CardMedia
                    sx={{
                        height: {
                            xs: 140,
                            sm: 120,
                            md: 140,
                            lg: 160,

                        },

                        width: {
                            xs: 140,
                            sm: 120,
                            md: 140,
                            lg: 160,

                        },
                        marginX: "auto",
                    }}
                    image={image}
                    title={name}
                />
            </Link>
            <CardContent sx={{ padding: 0 }}>
                <Link to={`/productdetails/${url}`}>
                    <Typography
                        sx={{
                            fontWeight: "500",
                            fontSize: {
                                xs: 14,
                                sm: 14,
                                md: 14,
                                lg: 14,
                                xl: 14,
                            },
                        }}
                        gutterBottom
                        align="center"
                        variant="caption"
                        component="div"
                    >
                        {name}
                    </Typography>
                </Link>
                <Box
                    variant="text"
                    sx={{
                        display: { xs: "grid", sm: "flex", xl: "flex" },
                        gap: 1,
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {salesPrice ? (
                        <>
                            <Box
                                sx={{
                                    textDecoration: "line-through",
                                    fontSize: {
                                        xs: 12,
                                        sm: 14,
                                        md: 14,
                                        lg: 14,
                                        xl: 14,
                                    },
                                }}
                            >
                                {<FormatPrice price={price} />}
                            </Box>
                            <Divider
                                sx={{
                                    height: 30,
                                    display: { xs: "none", lg: "block", xl: "block" },
                                }}
                                orientation="vertical"
                                variant="middle"
                                flexItem
                            />
                            <Box
                                sx={{
                                    color: "red",
                                    fontSize: {
                                        xs: 12,
                                        sm: 14,
                                        md: 14,
                                        lg: 14,
                                        xl: 14,
                                    },
                                }}
                                indicatorColor="primary"
                            >
                                {<FormatPrice price={salesPrice} />}
                            </Box>
                        </>
                    ) : (
                        <Button
                            sx={{
                                fontSize: {
                                    xs: 14,
                                    sm: 14,
                                    md: 14,
                                    lg: 16,
                                    xl: 14,
                                },
                            }}
                            indicatorColor="primary"
                        >
                            {<FormatPrice price={price} />}
                        </Button>
                    )}
                </Box>
            </CardContent>
            <CardActions
                sx={{
                    marginTop: 0,
                    paddingTop: 0,
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Button sx={{ marginTop: 0 }} variant="outlined" size="small">
                    BUY NOW
                </Button>
                <IconButton>
                    <AddShoppingCartIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
};

export default SliderCard;
