import React from 'react';
import { FaFacebook, FaInstagram, FaMapMarker, FaPhoneAlt, FaTiktok, FaYoutube } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className=" bg-zinc-800 p-8">
            <div className='w-10/12 xl:w-10/12 mx-auto lg:flex justify-between'>
                <div className="md:flex md:flex-col xl:flex xl:flex-col gap-4 justify-items-center">
                    <div className='flex items-center mx-auto border-[1px] rounded-full w-[274px] md:w-[300px] border-white text-white px-4 py-2 xl:mb-0 mb-2'>
                        <FaPhoneAlt className="text-2xl" />
                        <div className='border-l-2 ml-3 pl-3'>
                            <p className="text-sm">Customer Care</p>
                            <h2 className="font-bold">01711-380-679</h2>
                        </div>
                    </div>
                    <div className='flex items-center mx-auto border-[1px] rounded-full w-[274px] md:w-[300px] border-white text-white px-4 py-2'>
                        <FaMapMarker className="text-2xl" />
                        <div className='border-l-2 ml-3 pl-3'>
                            <p className="text-sm">Store Locator</p>
                            <h2 className="font-bold">Find Our Stores</h2>
                        </div>
                    </div>
                </div>

                <div className="text-white footer-menu lg:mt-0 mt-6 lg:inline-grid lg:grid-cols-1 gap-2 text-center lg:text-left">
                    <Link className="lg:border-0 border-r-[1px] border-white px-2">About Us</Link>
                    <Link className="lg:border-0 border-r-[1px] border-white px-2">Order Tracking</Link>
                    <Link className="lg:border-0 border-r-[1px] border-white px-2">Shop Address</Link>
                    <Link className="lg:border-0 border-r-[1px] border-white px-2">Careers</Link>
                    <Link className="mx-2">Delivery Terms & Condition</Link>
                </div>
                <div className="text-white footer-menu lg:mt-0 mt-6 lg:inline-grid lg:grid-cols-1 gap-2 text-center lg:text-left">

                    <Link className="lg:border-0 border-r-[1px] border-white px-2"> AGL Reward Card </Link>
                    <Link className="lg:border-0 border-r-[1px] border-white px-2"> EMI Policy </Link>
                    <Link className="lg:border-0 border-r-[1px] border-white px-2"> Privacy Policy </Link>
                    <Link className="lg:border-0 border-r-[1px] border-white px-2"> Refund Policy </Link>
                    <Link className="lg:border-0 border-r-[1px] border-white px-2"> Warranty Policy </Link>
                    <Link className="px-2"> How to Exchange Any Device </Link>

                </div>

                <div className='text-white lg:m-0 mt-10 lg:text-left text-center'>
                    <h2 className="font-bold ">Camera Bazar</h2>
                    <p>
                        55/A Block # 3, New Super Market<br />
                        Baitul Mukarram, Dhaka- 1000
                    </p>
                    <p className="text-orange-600">info@camerabazar.net</p>
                    <div className='flex gap-2 my-10 footer-icon lg:justify-start justify-center'>
                        <FaFacebook className="bg-[#505050] hover:bg-[#0A7CFF] hover:text-white rounded-md p-[6px] cursor-pointer" />
                        <FaInstagram className="bg-[#505050] hover:bg-[#b905d5] hover:text-white rounded-md p-[6px] cursor-pointer" />
                        <FaYoutube className="bg-[#505050] hover:bg-[#ff0101] hover:text-white rounded-md p-[6px] cursor-pointer" />
                        <FaTiktok className="bg-[#505050] hover:text-white rounded-md p-[6px] cursor-pointer" />
                    </div>
                </div>
            </div>

        </div >
    );
};

export default Footer;