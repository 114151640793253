import DOMPurify from "dompurify";
import React from "react";
import { Link } from "react-router-dom";
import { HiPencilSquare, HiOutlineTrash } from "react-icons/hi2";
import toast from "react-hot-toast";

const CategoryTable = ({ category, refetch }) => {
  const { _id, name, image } = category;

  const summary = DOMPurify.sanitize(category?.description);

  const handleDelete = (_id) => {
    const agree = window.confirm(
      `Are you sure about to delete- ${name} category`
    );
    if (agree) {
      fetch(`https://camerabazar-server.vercel.app/deletecategory/${_id}`, {
        method: "DELETE",
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.deletedCount > 0) {
            toast.success(`${name}- deleted Successfully!`);
            refetch();
          }
        });
    }
  };



  return (
    <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 dark:hover:bg-neutral-200">
      <td>
        <img className="w-16 p-2" src={image} alt="category img" />
      </td>
      <td>{name}</td>
      <td>
        <div dangerouslySetInnerHTML={{ __html: summary }}></div>
      </td>
      <td className="text-center">20</td>
      <td className="text-center">
        <Link
          to={`/dashboard/editcategory/${_id}`}
          className=" text-blue-600 text-xl"
        >
          <HiPencilSquare />
        </Link>
      </td>
      <td className="text-red-600 text-xl">
        <Link onClick={() => handleDelete(_id)} to="#">
          <HiOutlineTrash />
        </Link>
      </td>
    </tr>
  );
};

export default CategoryTable;
