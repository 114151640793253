import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaRegEye } from 'react-icons/fa';
import QuickView from '../../../Components/QuickView/QuickView';


const LatestProductCard = ({ mobile }) => {
    const { name, image, price, salesPrice } = mobile;
    const [url, setUrl_title] = useState();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        const url_title = name
            .replaceAll(' ', '_')
            .replaceAll('/', '@');
        setUrl_title(url_title);

    }, [name, url])



    return (
        <div className="group w-[200px] xl:w-[220px] overflow-hidden hover:shadow-lg hover:shadow-grey-300 lg:p-4 transition-all duration-500 p-2 xl:p-4 rounded-xl grid content-between relative"
        >

            <Link to={`/productdetails/${url}`}>
                <img className="mx-auto" src={image} alt="mobile" />
            </Link>
            <button onClick={setOpen}> <FaRegEye className="p-1 text-2xl text-orange-500 absolute top-4 -right-10 transition duration-300 ease-in-out transform delay-150 group-hover:-translate-x-12" /></button>
            <Link
                to={`/productdetails/${url}`}
                className="font-bold text-center mt-2"
            >
                {name}
            </Link>
            <h2 className="text-center font-bold text-orange-500">
                ৳ {price}
            </h2>
            <div className="mt-4 flex justify-center">
                <button className="w-fit border-solid border-[1px] border-orange-500 bg-orange-500 text-white rounded-[4px] px-[6px] py-[4px] text-[0.75rem] font-bold mr-1">
                    Buy Now
                </button>
                <button className="w-fit border-solid border-[1px] border-orange-500 text-orange-500 rounded-[4px] px-[6px] py-[4px] text-[0.75rem] font-semibold">
                    Add to Cart
                </button>
            </div>
            <QuickView open={open} handleClose={handleClose} handleOpen={handleOpen} mobile={mobile} />
        </div>
    );
};

export default LatestProductCard;