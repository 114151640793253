import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../Components/SideBar/SideBar";
import TopBar from "../Components/SideBar/TopBar";


const DashboardLayout = () => {

  return (
    <div className="flex flex-row">
      <SideBar />
      <div className="basis-11/12">
        <TopBar />
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;
