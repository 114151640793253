import React, { useContext } from "react";
import {
  FaBell,
  FaMoon,
  FaSearch,
  FaSignOutAlt,
  FaStore,
  FaTools,
} from "react-icons/fa";
import { AuthContext } from "../../contexts/AuthProvider";
import { Link } from "react-router-dom";

const TopBar = () => {
  const { logOut } = useContext(AuthContext);

  const handleLogOut = () => {
    logOut()
      .then(() => { })
      .catch((err) => console.log(err));
  };

  return (
    <div className="flex justify-between shadow-md p-4">
      <label className="relative lg:block hidden">
        {/* <span className="sr-only">Search</span> */}
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 ">
          <FaSearch className="icon"></FaSearch>
        </span>
        <input
          className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
          placeholder="Search for anything..."
          type="text"
          name="search"
        />
      </label>

      <div className="flex items-center gap-4 text-xl text-gray-700  ">
        <Link to="/" target="_blank"> <FaStore className="hover:text-orange-600" /></Link>
        <FaMoon />
        <FaBell />
        <FaTools />
        <FaSignOutAlt
          className="cursor-pointer hover:text-orange-600"
          onClick={handleLogOut}
        />
      </div>
    </div>
  );
};

export default TopBar;
