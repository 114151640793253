import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import FormatPrice from "../Helpers/FormatPrice";
import BasicTabs from "./BasicTabs";
import GroupedButtons from "./GroupedButtons";
import ScrollToTop from "../ScroolToTop";

const LatestProductDetails = () => {
  const latestProduct = useLoaderData();
  // console.log(latestProduct)
  const { _id, name, brand, price, salesPrice, image } = latestProduct;

  const description = DOMPurify.sanitize(latestProduct?.longDes);
  const summary = DOMPurify.sanitize(latestProduct?.shortDes);

  useEffect(() => {
    document.title = name;
  })

  // useEffect(() => {
  //   const urlRegex = /\s/g;
  //   const url_title = name.replace(urlRegex, '_');
  //   window.location = url_title;
  // }, [name])


  return (
    <div className="mx-auto w-10/12">
      <ScrollToTop />
      <div className="grid md:grid-cols-2 grid-cols-1 my-10">
        <div className="">
          <img className="mx-auto" src={image} alt="Images" />
        </div>
        <div>
          <h2 className="text-xl font-bold">{name}</h2>
          <p className="text-slate-600 border-2 w-fit px-2 py-1 border-b-orange-600 rounded-lg">
            {brand}
          </p>
          {/* <h2 className="text-md font-medium">{<FormatPrice price={price} />}</h2> */}
          <div className="flex gap-2 mt-4">
            {salesPrice ? (
              <>
                <h2 className="text-md font-medium line-through border px-2 rounded-md py-1">
                  {<FormatPrice price={price} />}
                </h2>
                <h2 className="text-md font-medium border w-fit rounded-md bg-orange-600 text-white px-2 py-1">
                  {<FormatPrice price={salesPrice} />}
                </h2>
              </>
            ) : (
              <h2 className="text-md font-medium">
                {<FormatPrice price={price} />}
              </h2>
            )}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: summary }}
            className="border p-4 my-4 w-fit rounded-md "
          ></div>
          <GroupedButtons />
          <Link className="button-orange" to="/">
            Buy Now
          </Link>
        </div>
      </div>
      {/* <div
        dangerouslySetInnerHTML={{ __html: description }}
        className="w-10/12 border mx-auto p-4 mb-10"
      ></div> */}
      <BasicTabs latestProduct={latestProduct} />
    </div>
  );
};

export default LatestProductDetails;
