import React from 'react';
import Hero from '../../Components/Hero';
import FeaturedCategories from './FeaturedCategories/FeaturedCategories';
import FeaturedProducts from './FeatureProducts/FeaturedProducts';
import HomeFirstBanner from './HomeBanner/HomeFirstBanner';
import HomeSecondBanner from './HomeBanner/HomeSecondBanner';
import LatestProduct from './LatestProduct/LatestProduct';
import ComingSoon from '../../Components/Modal/ComingSoon/ComingSoon';

const Home = () => {
    return (
        <div>
            <div className="mx-auto my-[-6px] lg:my-4 w-full lg:w-10/12">
                <Hero></Hero>
            </div>
            <FeaturedCategories />
            <LatestProduct />
            <FeaturedProducts />
            <HomeFirstBanner />
            <FeaturedProducts />
            <HomeSecondBanner />
            <ComingSoon />
        </div>

    );
};

export default Home;