import React, { createContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import ScrollToTop from '../Components/ScroolToTop';

export const cartDataContext = createContext();

const Main = () => {
    const [quickPreview, setQuickPreview] = useState(false);
    const [open, setOpen] = useState(false);
    const data = { quickPreview, setQuickPreview: setQuickPreview, open, setOpen }
    return (
        <cartDataContext.Provider value={data}>
            <div>
                {/* <ScrollToTop /> */}
                <Header />
                <Outlet open={open} setOpen={setOpen} setQuickPreview={setQuickPreview} />
                <Footer />
            </div>
        </cartDataContext.Provider>
    );
};

export default Main;