import { useQuery, Component } from "@tanstack/react-query";
import React from "react";
import Loading from "../../../Components/Loading";
import LatestProductCard from "./LatestProductCard";
import { Swiper, SwiperSlide } from "swiper/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderCard from "./SliderCard";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Silk Slider Setting
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  prevArrow: (
    <ArrowBackIosIcon
      sx={{ color: "#929292", ":hover": { color: "#F88825" } }}
    />
  ),
  nextArrow: (
    <ArrowForwardIosIcon
      sx={{ color: "#929292", ":hover": { color: "#F88825" } }}
    />
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";

// import "./styles.css";

// // import required modules
// import { Pagination } from "swiper";
// import SliderCard from "./SliderCard";
// import SilkSlider from "./SilkSlider";
// import Slider from "react-slick/lib/slider";

const LatestProduct = () => {
  const { data: latestMobile, isLoading } = useQuery({
    queryKey: ["latestMobile"],
    queryFn: async () => {
      const res = await fetch(
        "https://camerabazar-server.vercel.app/latestProducts"
      );
      const data = res.json();
      return data;
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="mb-10 w-10/12 mx-auto">
      <h2 className="text-2xl font-bold uppercase text-center">
        Latest Product
      </h2>
      <p className="text-center mb-10">
        Get your desired product from featured category
      </p>

      <>
        <Slider {...settings}>
          {latestMobile.map((mobile) => (
            <>
              <SwiperSlide>
                {/* <LatestProductCard key={mobile._id} mobile={mobile} /> */}
                <SliderCard key={mobile._id} mobile={mobile} />
              </SwiperSlide>
            </>
          ))}
        </Slider>
        {/* <Swiper
          slidesPerView={2}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {latestMobile.map((mobile) => (
            <>
              <SwiperSlide>
                <SliderCard key={mobile._id} mobile={mobile} />
              </SwiperSlide>
            </>
          ))}

        </Swiper> */}
      </>

      {/* <div className="w-full md:w-10/12 lg:w-10/12 xl:w-10/12 xl:my-10 my-4 mx-auto">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 justify-items-center mx-auto">
          {latestMobile.map((mobile) => (
            <LatestProductCard
              key={mobile._id}
              mobile={mobile}
            ></LatestProductCard>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default LatestProduct;
