import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { HiSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import SearchResult from "./SearchResult";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();

const ProductSearch = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  const { data: allMobile } = useQuery({
    queryKey: ["allMobile"],
    queryFn: async () => {
      const res = await fetch(
        "https://camerabazar-server.vercel.app/allproducts"
      );
      const data = res.json();
      return data;
    },
  });

  // const handleSearch = () => {
  let filteredResults = [];
  if (query) {
    filteredResults = allMobile.filter((product) =>
      product.name.toLowerCase().includes(query.toLowerCase())
    );
  }
  // setResults(filteredResults);
  // };

  return (
    <>
      {/* <div className="w-full md:w-2/3 mx-auto my-6 px-4 relative">
                <div className="relative">
                    <input
                        type="text"
                        className="block w-full py-3 px-4 pr-12 rounded-lg bg-gray-200 focus:bg-white focus:outline-none focus:ring focus:ring-blue-200 transition-all duration-200"
                        placeholder="Search products"
                        value={query}
                        onChange={e => setQuery(e.target.value)}
                    />
                    <button className="absolute top-0 right-0 px-4 py-3 text-gray-500" onClick={handleSearch}>
                        <HiSearch />
                    </button>
                </div>
            </div> */}

      <label className="relative lg:block group hidden">
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 ">
          <FaSearch className="icon"></FaSearch>
        </span>
        <input
          className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-2 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
          placeholder="Search for anything..."
          type="text"
          name="search"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />

        {filteredResults.length ? (
          <div
            // data-aos="fade-down"
            className="group-hover:visible invisible w-full shadow-xl mx-auto absolute bg-white p-2 rounded-md mt-2"
          >
            <div className="w-full grid lg:grid-cols-2 grid-cols-1 gap-2">
              {filteredResults.map((product) => (
                <SearchResult
                  key={product._id}
                  product={product}
                ></SearchResult>
              ))}
            </div>
          </div>
        ) : null}
      </label>
    </>
  );
};

export default ProductSearch;
