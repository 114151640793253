import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '3px solid #daa520',
    boxShadow: 24,
    p: 4,
    backgroundImage: 'url(https://static.vecteezy.com/system/resources/previews/002/145/134/original/eid-mubarak-greeting-islamic-illustration-design-with-beautiful-lantern-moon-and-arabic-calligraphy-free-vector.jpg)',
    backgroundSize: 'cover',
    height: {
        xs: 180,
        sm: 300,
        md: 350,
        lg: 350,
        xl: 350,
    },
    width: {
        xs: 280,
        sm: 400,
        md: 550,
        lg: 550,
        xl: 550,
    },
    borderRadius: 2,


};

const ComingSoon = () => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    React.useEffect(() => {
        setTimeout(() => handleOpen(true), 3000);
    }, []);

    return (
        <div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Modal data-aos="zoom-out"
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        ঈদ মোবারক
                    </Typography>
                    {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        ঈদ মোবারক
                    </Typography> */}
                </Box>
            </Modal>
        </div>
    );
};

export default ComingSoon;