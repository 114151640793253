import JoditEditor from 'jodit-react';
import React, { useRef, useState } from 'react'
import { toast, Toaster } from 'react-hot-toast';
// import React, { useState } from 'react'
// import toast, { Toaster } from 'react-hot-toast'
// import moment from 'moment'
// import useTitle from '../../../hooks/useTitle'
import { PhotoIcon } from '@heroicons/react/24/solid'

const AddProduct = ({ placeholder }) => {
  // useTitle('Add Service')
  //   const [service, setService] = useState({})

  const imageKey = process.env.REACT_APP_imageBB_key;
  const shortText =
  {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: placeholder || 'Product short description...'
  }
  const longTex =
  {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: placeholder || 'Product long description...'
  }


  const editor = useRef(null);
  const [shortDes, setShortDes] = useState('');
  const [longDes, setLongDes] = useState('');


  const addProduct = (event) => {
    event.preventDefault()
    const form = event.target
    const submissionTime = new Date().getTime()
    const rating = form.rating.value
    const title = form.title.value
    const price = form.price.value
    const thumbNailImage = form.thumbImage.value
    const formData = new FormData();
    formData.append('thumbImage', thumbNailImage);
    const url = `https://api.imgbb.com/1/upload?key=${imageKey}`
    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then(imgData => {
        if (imgData.success)
          console.log(imgData.data.url);
        const image = imgData.data.url
        const product = {
          submissionTime,
          image,
          rating,
          title,
          price,
          shortDes,
          longDes,
        }

        console.log(product)

        fetch('https://camerabazar-server.vercel.app/addproduct', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: `bearer ${localStorage.getItem('cb-token')}`
          },
          body: JSON.stringify(product),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.acknowledged)
              <div>
                {toast.success('Product added successfully!')}
                <Toaster />
              </div>
            // event.target.reset()
          })
      })
  };

  return (
    <div className="grid justify-items-center my-4">
      <h2>Add Product</h2>

      <form onSubmit={addProduct} className="mt-4 grid gap-2 w-11/12">
        <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
          Product Name
        </label>
        <input
          type="text"
          name="title"
          placeholder="Product Name"
          className="input input-bordered w-full"
          required
        />
        <div className="flex gap-4">
          <input
            type="text"
            name="price"
            className="input input-bordered w-1/2"
            placeholder="Price $"
            required
          ></input>
          <input
            type="number"
            name="rating"
            placeholder="Rating 1 to 5"
            min="1"
            max="5"
            step="0.5"
            className="input input-bordered w-1/2"
            required
          />
        </div>

        {/* <textarea
          type="text"
          name="content"
          className="textarea textarea-bordered"
          placeholder="Fill Up"
          required
        ></textarea> */}

        <div className="col-span-full">
          <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
            Cover photo
          </label>
          <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
            <div className="text-center">
              <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
              <div className="mt-4 flex text-sm leading-6 text-gray-600">
                <label
                  htmlFor="thumbImage"
                  className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <span>Upload a file</span>
                  <input id="thumbImage" name="thumbImage" type="file" className="sr-only" required />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 100kb</p>
            </div>
          </div>
        </div>


        <JoditEditor
          ref={editor}
          value={shortDes}
          config={shortText}
          onBlur={newContent => setShortDes(newContent)}
        />


        <JoditEditor
          ref={editor}
          value={longDes}
          config={longTex}
          // tabIndex={1} // tabIndex of textarea
          // onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
          onBlur={newContent => setLongDes(newContent)}
        />
        <button type="submit" className="bg-orange-600 text-white text-md font-bold uppercase px-2 py-1 rounded-md">
          Add Service
        </button>
        {/* <Toaster /> */}
      </form>
    </div>

  )
}

export default AddProduct
