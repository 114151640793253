import React from "react";
import Loading from "../../Components/Loading";
import MobileCard from "../../Pages/Home/FeatureProducts/mobileCard";
import { useQuery } from "@tanstack/react-query";

const AllProducts = () => {
  const { data: allMobile, isLoading } = useQuery({
    queryKey: ["allMobile"],
    queryFn: async () => {
      const res = await fetch("https://camerabazar-server.vercel.app/products");
      const data = res.json();
      return data;
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="mb-10">
      <h2 className="text-2xl font-bold uppercase text-center">
        Featured Products
      </h2>
      <p className="text-center">
        Get your desired product from featured category
      </p>
      <div className="w-full md:w-10/12 xl:w-10/12 xl:my-10 my-4 mx-auto">
        <div className="grid gap-4 grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-6 justify-between xl:gap-4 mx-auto justify-items-center">
          {allMobile.map((mobile) => (
            <MobileCard key={mobile._id} mobile={mobile}></MobileCard>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllProducts;
